<template>
    <div class="index">
        <headers :headIndex="2"></headers>
        <top-img></top-img>

        <router-view></router-view>
        <footers></footers>
    </div>
</template>
<script>
import headers from '@/components/headers'
import footers from '@/components/footers'
import topImg from '@/components/topImg'
import paging from '@/components/paging'
export default {
    name: 'mechanismIndex',
    components:{
        headers,
        footers,
        topImg,
        paging
    },
}
</script>
<style lang="scss" scoped>
    .index{
        width: 100vw;
    }
   
</style>